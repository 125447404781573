<template>
  <div class="outside" style="background: #F9F7F3;">
    <div class="introduction">
      <div class="edition_heart">
        <div class="top-little-nav">
          首页
          <i class="el-icon-arrow-right" style="margin: 0 5px;"></i>
          修复工艺
          <i class="el-icon-arrow-right" style="margin: 0 5px;"></i>
          {{ detailsData[index].title }}
        </div>
        <div class="title">{{ detailsData[index].title }}</div>
        <div class="titleEng">{{ detailsData[index].titleEng }}</div>
        <div class="photos">
          <el-row>
            <el-col :span="24">
              <img :src="detailsData[index].imgUrl" alt="">
            </el-col>
          </el-row>
        </div>
        <el-row>
          <el-col :span="24">
            <div class="content" v-html="detailsData[index].content"></div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="repair_case">
      <div class="edition_heart">
        <div class="title">修复案例</div>
        <div class="titleEng">Repair case</div>
        <div class="photos">
          <swiper ref="mySwiper" :options="swiperOptions" style="width: 100%" :autoplay="true">
            <swiper-slide v-for="(   item, index   ) in    detailsData[index].repairCaseImg   " :key="index">
              <div class="repair_case_photos">
                <div style="position: relative;">
                  <img ref="swiperImg" :src="item.beforeImg" />
                  <p class="img-tips">修复前</p>
                </div>
                <div style="position: relative;">
                  <img ref="swiperImg" :src="item.afterImg" />
                  <p class="img-tips">修复后</p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import banner from '/src/components/banner.vue'
// vue-awesome-swiper@4.x 版本首字母 S 要用大写
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'

export default {
  name: 'RepairProcessDetails',
  components: { banner, Swiper, SwiperSlide },
  data() {
    return {
      detailsData: [{
        title: '金属文物保护修复部简介',
        titleEng: 'Restoration of metal cultural relics',
        imgUrl: require('/src/assets/img/repairProcess/metal-big.jpg'),
        content:
          '<p>金属文物保护修复部现有人员20人，技术总监1人、文物修复师17人、文案2人，均毕业于文物修复与鉴定专业。修复人员技术成熟、经验丰富。截至2021年底 已完成金属器保护修复项目近50个，共修复文物6000余件套。其中修复一级文物30余件、二级文物600余件、三级文物2000余件。</p><p>金属文物保护修复部还致力于文物修复技术、文物修复工具、文物修复材料的改进与研发。部门人员在项目工作中稳扎稳打、总结经验并积极探索。在现有技术 平台上改进了青铜器矫形工作台、低温锡合金补配材料、铜镜化学镀银技术、金属文物镀金铜技术、酒石酸钾钠溶液局部除锈技术、铁器文物电解除锈技术、文物激 光焊接技术等。并在实践中合理运用，得到了专家认同。</p><p> 金属文物保护修复部将在未来的工作会严格遵守《文物保护法》和《文物保护法实施条例》，不忘初心保持稳扎稳打、 小心谨慎的工作态度和虚心学习、 勇于 创新的精神继续为文物保护事业尽绵薄之力。</p>',
        introductionImg: [
          { imgUrl: require('/src/assets/img/repairProcess/metal-1.jpg') },
          { imgUrl: require('/src/assets/img/repairProcess/metal2.jpg') },
          { imgUrl: require('/src/assets/img/repairProcess/metal3.jpg') }
        ],
        repairCaseImg: [{
          afterImg: require('/src/assets/img/repairProcess/after1.png'),
          beforeImg: require('/src/assets/img/repairProcess/before1.png'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/after2.png'),
          beforeImg: require('/src/assets/img/repairProcess/before2.png'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/after3.png'),
          beforeImg: require('/src/assets/img/repairProcess/before3.png'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/after4.jpg'),
          beforeImg: require('/src/assets/img/repairProcess/before4.jpg'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/after5.jpg'),
          beforeImg: require('/src/assets/img/repairProcess/before5.jpg'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/after6.jpg'),
          beforeImg: require('/src/assets/img/repairProcess/before6.jpg'),
        },],
      }, {
        title: '陶瓷文物保护修复部简介',
        titleEng: 'Restoration of ceramic relics',
        imgUrl: require('/src/assets/img/repairProcess/ceramics-big.jpg'),
        content:
          '<p>陶瓷文物保护修复部现有人员12人。其中获得山东省文物修复师资格者4人。涵盖陶艺、雕塑、色彩、绘画、化工等方面的人才。陶瓷文物保护修复部所用修复 材料大都是进口专业文物保护材料，修复设备齐全，修复技术先进。从打磨补配再到上色等分工明确、专业性强。</p><p> 陶瓷文物保护修复部成立至今已完成多个陶瓷文物保护修复项目，地域涵盖山东、江苏、河北、江西、甘肃、湖南、湖北、广东等，修复文件近2000件。 研究 成功室温开发修复釉、古陶瓷低残留清洗剂等多项陶瓷修复技术。陶瓷文物保护修复部每名成员都有很强的责任心，随时准备迎接各种挑战。</p>',
        introductionImg: [
          { imgUrl: require('/src/assets/img/repairProcess/ceramics-1.jpg') },
          { imgUrl: require('/src/assets/img/repairProcess/ceramics2.jpg') },
          { imgUrl: require('/src/assets/img/repairProcess/ceramics3.jpg') },
        ],
        repairCaseImg: [{
          afterImg: require('/src/assets/img/repairProcess/ceramicsAfter1.png'),
          beforeImg: require('/src/assets/img/repairProcess/ceramicsBefore1.png'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/ceramicsAfter2.png'),
          beforeImg: require('/src/assets/img/repairProcess/ceramicsBefore2.png'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/ceramicsAfter3.png'),
          beforeImg: require('/src/assets/img/repairProcess/ceramicsBefore3.png'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/ceramicsAfter4.jpg'),
          beforeImg: require('/src/assets/img/repairProcess/ceramicsBefore4.jpg'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/ceramicsAfter5.jpg'),
          beforeImg: require('/src/assets/img/repairProcess/ceramicsBefore5.jpg'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/ceramicsAfter6.jpg'),
          beforeImg: require('/src/assets/img/repairProcess/ceramicsBefore6.jpg'),
        }],
      }, {
        title: '书画文物保护修复部简介',
        titleEng: 'Restoration of calligraphy and painting relics',
        imgUrl: require('/src/assets/img/repairProcess/painting-big.jpg'),
        content:
          '<p>书画文物保护修复部主要工作内容包括书画装裱、古书画保护修复。书画文物保护修复部现有修复人员17名，均具有多年实践手工经验，其中省级文物修复师2 名。</p><p> 近年来先后修复书画作品3000余件(套)。其中一级文物10余件、二级文物400余件、三级文物1200余件。 书画文物保护修复部在修复方面一直遵循着不改变文物原状、最小干预、可再处理性的修复原则。 古书画在传世的过程中， 由于收藏保管不善造成受潮发霉、 污迹满目、虫蛀鼠咬，以及绫绢和纸的自然老化都会使书画产生破洞、糟朽断裂等等，如果不及时修复，就会影响古旧书画的寿命和艺术价值，会朽烂而毁之一旦。</p>',
        introductionImg: [
          { imgUrl: require('/src/assets/img/repairProcess/painting-1.jpg') },
          { imgUrl: require('/src/assets/img/repairProcess/painting2.jpg') },
          { imgUrl: require('/src/assets/img/repairProcess/painting3.jpg') },
        ],
        repairCaseImg: [{
          afterImg: require('/src/assets/img/repairProcess/paintingAfter1.png'),
          beforeImg: require('/src/assets/img/repairProcess/paintingBefore1.png'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/paintingAfter2.png'),
          beforeImg: require('/src/assets/img/repairProcess/paintingBefore2.png'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/paintingAfter3.png'),
          beforeImg: require('/src/assets/img/repairProcess/paintingBefore3.png'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/paintingAfter4.jpg'),
          beforeImg: require('/src/assets/img/repairProcess/paintingBefore4.jpg'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/paintingAfter5.jpg'),
          beforeImg: require('/src/assets/img/repairProcess/paintingBefore5.jpg'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/paintingAfter6.jpg'),
          beforeImg: require('/src/assets/img/repairProcess/paintingBefore6.jpg'),
        }],
      }, {
        title: '古籍文物保护修复部简介',
        titleEng: 'Restoration of Ancient Books and Cultural Relics',
        imgUrl: require('/src/assets/img/repairProcess/books-big.jpg'),
        content:
          '<p>古籍文物保护修复部主要工作包括古籍善本、经折、丝帛制品的保护、修复等。目前部门现有主管1名，修复工作人员10名。 员工皆具有多年纸质文物修复经验，其中有3人被评为山东省 省级文物修复师。</p><p> 截止成立至今，古籍部先后修复古籍、经折作品近700册，合计150余套。其中三级文物173册29套。</p><p> 截止成立至今，古籍部先后修复古籍、经折作品近700册，合计150余套。其中三级文物173册29套。</p><p> 中华文化源远流长，文献资料的传世量巨大。部门始终秉持着文物修复的原则和职业操守，公平细致的对待每一件手中的文物。同时在修复技术和材料方面也在不断的改进、研发实践。近 年来，公司和山东大学生命科学学院合作，在文物霉菌、微生物的污染防治方面已经取得了实质性的进展。 未来的道路上，我们将以更全面、专业的服务带给需要的客户，为古籍文物的保护事 业竭尽绵薄之力。</p>',
        introductionImg: [
          { imgUrl: require('/src/assets/img/repairProcess/books1.jpg') },
          { imgUrl: require('/src/assets/img/repairProcess/books2.jpg') },
          { imgUrl: require('/src/assets/img/repairProcess/books3.jpg') },
        ],
        repairCaseImg: [{
          afterImg: require('/src/assets/img/repairProcess/booksAfter1.png'),
          beforeImg: require('/src/assets/img/repairProcess/booksBefore1.png'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/booksAfter2.png'),
          beforeImg: require('/src/assets/img/repairProcess/booksBefore2.png'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/booksAfter3.png'),
          beforeImg: require('/src/assets/img/repairProcess/booksBefore3.png'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/booksAfter4.jpg'),
          beforeImg: require('/src/assets/img/repairProcess/booksBefore4.jpg'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/booksAfter5.jpg'),
          beforeImg: require('/src/assets/img/repairProcess/booksBefore5.jpg'),
        }, {
          afterImg: require('/src/assets/img/repairProcess/booksAfter6.jpg'),
          beforeImg: require('/src/assets/img/repairProcess/booksBefore6.jpg'),
        }],
      }],
      isMounted: false,
      swiperOptions: {
        slidesPerView: 3, //一行显示3个
        spaceBetween: 10, //间隔10
        freeMode: true,
        speed: 1000, //滑动速度
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        loop: true,
        autoplay: {
          //这个参数很重要,当外力干扰轮播图时，它能够继续实现自我滚动
          disableOnInteraction: false,
          delay: 5000, //5秒切换一次
        },
      },
      index: 0,
    }
  },
  mounted() {
    this.isMounted = true
  },
  computed: {
    customswiper() {
      let swiper
      if (this.isMounted) swiper = this.$refs.mySwiper.$swiper
      return swiper
    },
  },
  methods: {},
  watch: {
    index: {
      handler(newVal, oldVal) {
        if (this.$route.params.index == undefined) {
          this.index = 0
        } else {
          this.index = this.$route.params.index
        }
      },
      immediate: true,
    },
    $route() { },
  },
}
</script>

<style lang="scss" scoped>
.top-little-nav {
  color: #999;
  font-size: 14px;
  margin-top: 20px;
}

.img-tips {
  width: 70px;
  height: 32px;
  background: rgba(0, 0, 0, .4);
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 15px;
  top: 15px;
}

.repair-intro {
  position: relative;
}

.repair-intro-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.repair-dec {
  width: 24px !important;
  border: none !important;
}

.repair-left-title {
  display: flex;
  align-items: center;
  margin: 60px 0 0 45px;
}

.repair-intro-title {
  font-size: 32px;
  color: #fff;
  margin: 0 12px;
}

.repair-little-fc {
  font-size: 14px;
  color: #fff;
  margin-top: 5px;
}

.repair-num {
  font-size: 32px;
  font-weight: 700;
  color: #fff;
}

.repair-left-bot {
  margin: 0 0 70px 50px;
}

@media screen and (min-width: 921px) {
  .edition_heart {
    width: 62.5%;
  }

  .outside {
    padding-top: 120px;
  }

  .title {
    font-size: 32px;
    font-family: PingFang SC, PingFang SC-Heavy;
    font-weight: 800;
    text-align: left;
    color: #333;
    margin-top: 70px;
  }

  .titleEng {
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 12px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(102, 102, 102, .4);
    line-height: 30px;
    padding-bottom: 18px;
    border-bottom: 1px solid #D8D8D8;
    position: relative;
  }

  .titleEng::before {
    content: "";
    display: block;
    width: 109px;
    height: 6px;
    background: #A40000;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .edition_heart {
    width: 80%;
  }

  .outside {
    padding-top: 120px;
  }
}

@media screen and (max-width: 768px) {
  .edition_heart {
    width: 90%;
  }

  .edition_heart {
    padding-top: 70px;
  }

  .introduction {
    padding-top: 70px;
  }

  ::v-deep .edition_heart {
    padding-top: 30px;
  }
}

* {
  margin: 0;
  padding: 0;
}

.outside {
  .introduction {
    width: 100%;

    .content {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #666;
      line-height: 30px;
      text-indent: 2em;
    }

    .photos {
      width: 100%;
      height: auto;
      margin-bottom: 15px;
      margin-top: 40px;

      img {
        width: 95%;
        height: auto;
        border: 5px solid rgba(255, 255, 255, 0.4);
      }
    }
  }

  .repair_case {
    margin-top: 20px;
    width: 100%;
    position: relative;

    .after {
      font-size: 24px;
      font-family: Source Han Sans CN, Source Han Sans CN-Normal;
      margin: 38px 0 18px;
      position: absolute;
      right: 0;
    }

    .photos {
      .repair_case_photos {
        margin: 50px 0 80px;
        cursor: pointer;
        width: 100%;

        img {
          width: 97%;
          box-sizing: border-box;
          height: auto;
          border: 15px solid #fff;
        }
      }
    }
  }

  // 版心
  .edition_heart {
    // width: 62.5%;
    margin: 0px auto;
    position: relative;
  }
}
</style>
